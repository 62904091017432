import { EntityStore, StoreConfig } from '@datorama/akita';
import { ScoringBucketState } from '@app-state/scoring-bucket/scoring-bucket.state';
import { Stores } from '@app-state/shared/stores-configuration';

/**
 * Scoring bucket store
 */
@StoreConfig(Stores.scoringBuckets)
export class ScoringBucketStore extends EntityStore<ScoringBucketState> {
  constructor() {
    super();
  }
}
