import { Injectable } from '@angular/core';
import { BaseClient } from '@app-clients/base.client';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { ExtractCsvDataQuery } from '@app-models/assessment/extract-data/extract-csv-data-query';
import { convertDateToDateTime } from '@app-utils/helpers/date-convertors';
import { ParticipantExtractQuery } from '@app-models/participant/participant-extract-query';
import { EventLogExtractQuery } from '@app-models/account/event-log-extract-query';

@Injectable()
export class DataExtractClient extends BaseClient {
  constructor(httpClient: HttpClient, env: AppEnvironment) {
    super('extractCsv', httpClient, env);
  }

  async getExtractData(data: ExtractCsvDataQuery): Promise<HttpResponse<Blob>> {
    return await this.getWithOptions(
      ['assessment'],
      [
        { assessmentRevisionId: data.assessmentRevisionId },
        { identityLevel: data.identityLevel },
        { maxRecords: data.maxRecords },
        { randomSeed: data.randomSeed },
        { includeDatabaseIds: data.includeDatabaseIds },
        {
          from: convertDateToDateTime(data.from),
        },
        {
          through: convertDateToDateTime(data.through, false),
        },
        {
          includeAllData: data.includeAllData,
        },
      ],
      'blob',
    );
  }

  public async getParticipantExtract(
    data: ParticipantExtractQuery,
  ): Promise<HttpResponse<Blob>> {
    return await this.getWithOptions(
      ['participant'],
      [
        { assessmentIds: data.assessmentIds },
        { from: convertDateToDateTime(data.from) },
        { through: convertDateToDateTime(data.through, false) },
        { participantStatusType: data.participantStatusType },
      ],
      'blob',
    );
  }

  //Make getUserExtract Method
  public async getUserExtract(): Promise<HttpResponse<Blob>> {
    return await this.getWithOptions(['users'], [], 'blob');
  }

  public async getEventLogExtract(
    data: EventLogExtractQuery,
  ): Promise<HttpResponse<Blob>> {
    return await this.getWithOptions(
      ['eventLog'],
      [
        { from: convertDateToDateTime(data.from) },
        { through: convertDateToDateTime(data.through, false) },
      ],
      'blob',
    );
  }
}
