/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { Observable } from 'rxjs';
import { Index } from '@app-types/index.type';
import { DatePipe } from '@angular/common';
import { filterNotNull } from '@app-utils/helpers/array';

/**
 * Base client to be inherited in other clients. This client specifies any common logic
 * such as retries and others. It exposes promises to allow for async/await use in
 * application. If additional modification to the subscription is required then
 * it also exposes the observable version of HTTP call.
 */
export abstract class BaseClient {
  private readonly _datePipe: DatePipe = new DatePipe('en-US');
  private readonly _baseUrl: string;

  /**
   * @param route Route of the client
   * @param httpClient HttpClient
   * @param appEnvironment Application environment
   * @protected
   */
  protected constructor(
    protected readonly route: string,
    protected readonly httpClient: HttpClient,
    protected readonly appEnvironment: AppEnvironment,
  ) {
    this._baseUrl = this.appEnvironment.baseApiUrl + this.route;
  }

  /**
   * Initiates a GET request returning a promise that will resolve with provided typed value (default void).
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @param responseType Type of response to get from request.
   * @param observe Observe parameter.
   * @protected
   */
  protected getWithOptions(
    routeSegments?: any[],
    queryString?: { [key: string]: any }[],
    responseType?: string,
  ): Promise<any> {
    return this.getWithOptions$(
      routeSegments,
      queryString,
      responseType,
    ).toPromise();
  }

  /**
   * Initiates a GET request returning an observable that will emit provided typed value (default void).
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @param responseType Type of response to get from request.
   * @protected
   */
  protected getWithOptions$(
    routeSegments?: any[],
    queryString?: { [key: string]: any }[],
    responseType?: string,
  ): Observable<any> {
    return this.httpClient.get(this.getRemoteUrl(routeSegments, queryString), {
      responseType: responseType as 'json',
      observe: 'response',
    });
  }

  /**
   * Initiates a GET request returning a promise that will resolve with provided typed value (default void).
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected get<TResponse>(
    routeSegments?: any[],
    queryString?: { [key: string]: any }[],
  ): Promise<TResponse> {
    return this.get$<TResponse>(routeSegments, queryString).toPromise();
  }
  /**
   * Initiates a GET request returning an observable that will emit provided typed value (default void).
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected get$<TResponse>(
    routeSegments?: any[],
    queryString?: { [key: string]: any }[],
  ): Observable<TResponse> {
    return this.httpClient.get<TResponse>(
      this.getRemoteUrl(routeSegments, queryString),
    );
  }

  /**
   * Initiates a POST request returning a promise that will resolve with provided typed value (default void).
   * @param body Body of the request
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected post<TResponse>(
    body?: any,
    routeSegments?: any[],
    queryString?: { [key: string]: any }[],
  ): Promise<TResponse> {
    return this.post$<TResponse>(body, routeSegments, queryString).toPromise();
  }
  /**
   * Initiates a POST request returning an observable that will emit provided typed value (default void).
   * @param body Body of the request
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected post$<TResponse>(
    body?: any,
    routeSegments?: any[],
    queryString?: { [key: string]: any }[],
  ): Observable<TResponse> {
    return this.httpClient.post<TResponse>(
      this.getRemoteUrl(routeSegments, queryString),
      body,
    );
  }

  /**
   * Initiates a PUT request returning a promise that will resolve with provided typed value (default void).
   * @param body Body of the request
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected put<TResponse = void>(
    body?: any,
    routeSegments?: any[],
    queryString?: Index[],
  ): Promise<TResponse> {
    return this.put$<TResponse>(body, routeSegments, queryString).toPromise();
  }
  /**
   * Initiates a PUT request returning an observable that will emit provided typed value (default void).
   * @param body Body of the request
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected put$<TResponse = void>(
    body?: any,
    routeSegments?: any[],
    queryString?: Index[],
  ): Observable<TResponse> {
    return this.httpClient.put<TResponse>(
      this.getRemoteUrl(routeSegments, queryString),
      body,
    );
  }
  /**
   * Initiates a PATCH request returning a promise that will resolve with provided typed value (default void).
   * @param body Body of the request
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected patch<TResponse = void>(
    body?: any,
    routeSegments?: any[],
    queryString?: Index[],
  ): Promise<TResponse> {
    return this.patch$<TResponse>(body, routeSegments, queryString).toPromise();
  }
  /**
   * Initiates a PATCH request returning an observable that will emit provided typed value (default void).
   * @param body Body of the request
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @protected
   */
  protected patch$<TResponse = void>(
    body?: any,
    routeSegments?: any[],
    queryString?: Index[],
  ): Observable<TResponse> {
    return this.httpClient.patch<TResponse>(
      this.getRemoteUrl(routeSegments, queryString),
      body,
    );
  }
  /**
   * Initiates a DELETE request returning a promise that will resolve with provided typed value (default void).
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @param body Body object for the request
   * @protected
   */
  protected delete<TResponse = void>(
    routeSegments?: any[],
    queryString?: Index[],
    body?: any,
  ): Promise<TResponse> {
    return this.delete$<TResponse>(
      routeSegments,
      queryString,
      body,
    ).toPromise();
  }
  /**
   * Initiates a DELETE request returning an observable that will emit provided typed value (default void).
   * @param routeSegments Route segments for the request. It will be appended to the base URL.
   * @param queryString Query string values for the request.
   * @param body Body object for the request
   * @protected
   */
  protected delete$<TResponse = void>(
    routeSegments?: any[],
    queryString?: Index[],
    body?: any,
  ): Observable<TResponse> {
    return this.httpClient.delete<TResponse>(
      this.getRemoteUrl(routeSegments, queryString),
      { body },
    );
  }
  /**
   * Gets the URL for the remote endpoint.
   * @param segments Optional route segments appended to the route
   * @param queryString Optional query string appended to the route
   * @protected
   */
  protected getRemoteUrl(
    segments?: any[],
    queryString?: { [key: string]: any }[],
  ): string {
    let url = this._baseUrl;

    if (segments) {
      url += this.getSegmentsString(...segments);
    }

    if (queryString) {
      url += '?' + this.getQueryString(...queryString);
    }

    return url;
  }

  protected getSegmentsString(...segments: string[] | number[]): string {
    let url = '';

    if (segments && segments.length > 0) {
      for (const segment of segments) {
        url += `/${segment}`;
      }
    }

    return url;
  }

  /**
   * Converts values to query string
   * @param values
   * @protected
   */
  protected getQueryString(...values: { [key: string]: any }[]): string {
    const parts = [];
    for (const obj of filterNotNull(values)) {
      for (const property of Object.getOwnPropertyNames(obj)) {
        const value = obj[property];
        // Leave this as is. Need explicit check for null & undefined because some of the properties might be bool
        if (value === null || value === undefined) continue;

        parts.push(
          this.createQueryStringPair(
            property,
            this.getFormattedQueryStringValue(value),
          ),
        );
      }
    }

    return parts.length > 0 ? parts.join('&') : '';
  }
  private createQueryStringPair(key: string, value: string): string {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }
  private getFormattedQueryStringValue(value: any): string {
    if (value instanceof Date) return this.formatDate(value);

    return value.toString();
  }
  private formatDate(date: Date): string {
    return this._datePipe.transform(date, 'YYYY-MM-dd');
  }
}
