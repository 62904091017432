import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ReminderScheduleState } from '@app-state/reminder-schedule/reminder-schedule.state';
import { ReminderScheduleStore } from '@app-state/reminder-schedule/reminder-schedule.store';

@Injectable()
export class ReminderScheduleQuery extends QueryEntity<ReminderScheduleState> {
  constructor(private readonly _reminderScheduleStore: ReminderScheduleStore) {
    super(_reminderScheduleStore);
  }
}
