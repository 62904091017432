import { Injectable } from '@angular/core';
import { AccountQuery } from '@app-state/account/account.query';
import { AccountWithOwner } from '@app-models/account/account-with-owner';
import { AccountStore } from '@app-state/account/account.store';
import { AccountOwner } from '@app-models/account/account-owner';
import { map } from 'rxjs/operators';
import { Account } from '@app-models/account/account';
import { Observable } from 'rxjs';

@Injectable()
export class AccountOwnerService {
  constructor(
    private readonly _accountQuery: AccountQuery,
    private readonly _accountStore: AccountStore,
  ) {}

  /**
   * Checks if user owns the currently selected account
   * @param personId ID of the person to check ownership for
   */
  public userOwnsCurrentAccount(personId: string): boolean {
    if (!this._accountQuery.getActive().accountOwners) return;
    return this._accountQuery
      .getActive()
      .accountOwners.some(ao => ao.personId === personId);
  }

  /**
   * Checks if person is an account owner for the given account
   * @param personId Id of the person being checked for ownership
   * @param accountId Id of the account being checked
   */
  public userOwnsAccount(personId: string, accountId: string): boolean {
    if (!this._accountQuery.getEntity(accountId).accountOwners) return;
    return this._accountQuery
      .getEntity(accountId)
      ?.accountOwners.some(acc => acc.personId == personId);
  }

  /**
   * Gets all accounts with name that the person is an owner of
   * @param personId ID of the person for owned account retrieval
   */
  public getOwnedAccounts(personId: string): AccountWithOwner[] {
    return this.mapOwnedAccounts(this._accountQuery.getAll(), personId);
  }

  /**
   * Provides a continuously updated stream of a person's owned accounts
   * @param personId ID of the person for owned account retrieval
   */
  public getOwnedAccounts$(personId: string): Observable<AccountWithOwner[]> {
    return this._accountQuery
      .selectAll()
      .pipe(map(accounts => this.mapOwnedAccounts(accounts, personId)));
  }

  /**
   * Maps the owned accounts for a specific person based on the person's ID.
   *
   * @param {Account[]} accounts - The list of accounts to map.
   * @param {string} personId - The ID of the person whose owned accounts to map.
   * @returns {AccountWithOwner[]} - An array of mapped owned accounts for the given person ID.
   * @private
   */
  private mapOwnedAccounts(
    accounts: Account[],
    personId: string,
  ): AccountWithOwner[] {
    return accounts.flatMap(acc =>
      (acc.accountOwners ?? [])
        .filter(ao => ao.personId === personId)
        .map(ownedAccount => ({
          accountName: acc.name,
          personId: ownedAccount.personId,
          assignedOn: ownedAccount.assignedOn,
        })),
    );
  }

  /**
   * Updates account store to either add or remove an account owner
   * @param personId Id of the person being added or removed
   * @param accountId Id of the account being updated
   * @param shouldRemove Whether or not an account owner is being added or removed
   */
  public updateOwnedAccount(
    personId: string,
    accountId: string,
    shouldRemove: boolean,
  ): void {
    const ownedAccounts = this._accountQuery.getEntity(accountId).accountOwners;

    if (ownedAccounts.some(ao => ao.personId === personId) && !shouldRemove)
      return;

    if (shouldRemove) {
      this._accountStore.update(accountId, {
        accountOwners: ownedAccounts.filter(ao => ao.personId !== personId),
      });
    } else {
      this._accountStore.update(accountId, {
        accountOwners: [
          ...ownedAccounts,
          { personId: personId, assignedOn: new Date() } as AccountOwner,
        ],
      });
    }
  }
}
