import { Injectable } from '@angular/core';
import { AccountClient } from '@app-clients/account/account.client';
import { AccountStore } from './account.store';
import { Account } from '@app-models/account/account';

/**
 * Service for manipulating the account store.
 */
@Injectable()
export class AccountService {
  constructor(
    private readonly _accountStore: AccountStore,
    private readonly _accountsClient: AccountClient,
  ) {}
  /**
   * Loads all user accounts from remote server
   */
  async loadAccounts(): Promise<void> {
    this._accountStore.setLoading(true);
    const accounts = await this._accountsClient.getAccounts();

    this._accountStore.set(accounts);
    this._accountStore.setLoading(false);
  }

  /**
   * Selects an account to be the active one.
   * WARNING: Do not use this to select account for user - use AuthService for that.
   * @param account Account to switch to
   * @see AuthService
   */
  selectAccount(account: Partial<Account>): void {
    this._accountStore.setActive(account?.accountId);
  }
}
