import { NgModule } from '@angular/core';
import { CurrentUserClient } from '@app-clients/people/current-user.client';
import { UsersClient } from '@app-clients/people/users.client';
import { UserQuery, UserService, UserStore } from '@app-state/user';

@NgModule({
  providers: [
    CurrentUserClient,
    UsersClient,
    UserQuery,
    UserStore,
    UserService,
  ],
})
export class UserServiceModule {}
