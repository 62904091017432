import { Injectable } from '@angular/core';
import { BaseClient } from '@app-clients/base.client';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { UserFilter } from '@app-models/user/user-filter';
import { PageQuery } from '@app-models/query/page.query';
import { User } from '@app-models/user/user';
import { CreateUser } from '@app-models/user/create-user';
import { UserHighMatchRole } from '@app-models/user/user-highmatch-role';
import { ApiResponse } from '@app-responses/api-response';
import { AddRemoveAccount } from '@app-models/people/add-remove-account';
import { CompassRole } from '@app-models/roles';

@Injectable()
export class UsersClient extends BaseClient {
  constructor(httpClient: HttpClient, env: AppEnvironment) {
    super('users', httpClient, env);
  }

  getUsers(filter?: UserFilter, page?: PageQuery): Promise<User[]> {
    if (filter?.isAccountOwner || filter?.role === CompassRole.All)
      filter = { ...filter, role: undefined };

    return this.get<User[]>(null, [filter, page]);
  }

  /**
   * Gets a user by its external ID
   * @param externalId External ID of the user
   */
  getByExternalId(externalId: string): Promise<User> {
    return this.get<User>([externalId]);
  }

  /**
   * Gets a user by person ID
   * @param personId Person ID of the user
   */
  getById(personId: string): Promise<User> {
    return this.get<User>([personId]);
  }

  /**
   * Gets a user's HighMatch roles
   * @param personId external ID of a user
   */
  async getHighMatchRoles(personId: string): Promise<UserHighMatchRole[]> {
    const response = await this.get<ApiResponse<UserHighMatchRole[]>>([
      'roles',
      'highmatch',
      personId,
    ]);
    return response.data;
  }

  /**
   * Toggle a user's publisher role
   * @param personId external ID of a user
   */
  async togglePublisherRole(personId: string): Promise<boolean> {
    try {
      const response = await this.put<ApiResponse>(null, [
        'roles',
        'toggle',
        'publisher',
        personId,
      ]);
      return response.isSuccess;
    } catch (_) {
      return false;
    }
  }

  /**
   * Creates a new user in the system
   * @param userDto User representation DTO
   */
  create(userDto: CreateUser): Promise<User> {
    return this.post<User>(userDto);
  }

  /**
   * Updates an existing user in the system
   * @param personId Person ID of the user to update
   * @param userDto User representation DTO
   */
  update(personId: string, userDto: CreateUser): Promise<User> {
    return this.put<User>(userDto, [personId]);
  }

  addToAccount(addToAccountData: AddRemoveAccount): Promise<void> {
    return this.put(addToAccountData, ['addAccount']);
  }

  removeFromAccount(removeFromAccountData: AddRemoveAccount): Promise<void> {
    return this.delete(['removeAccount'], null, removeFromAccountData);
  }

  /**
   * Resets the users' password
   * @param personId Person ID of the user
   */
  async resetPassword(personId: string): Promise<boolean> {
    try {
      await this.patch(null, ['resetpassword', personId]);
    } catch (e) {
      return false;
    }

    return true;
  }

  /**
   * Toggles users' active status
   * @param personId Person ID of the user
   */
  toggleActivation(personId: string): Promise<void> {
    return this.patch(null, ['activate', personId]);
  }

  /**
   * Checks if user email already exists or not
   * @param email email of the user
   * returns boolean
   */
  async checkIfEmailExists(email: string): Promise<boolean> {
    try {
      return await this.get<boolean>(['exists', email]);
    } catch (e) {
      return false;
    }
  }
}
