import { SortedArray, SortProp } from '@app-utils/sorted-array';

/**
 * Filters an array and returns the values that are not null
 * @param values Array to filter
 */
export function filterNotNull<TArray>(values: TArray[]): TArray[] {
  return values.filter(v => v);
}

/**
 * Sorts elements in array by ascending order
 * @param values Elements to sort
 * @param prop Property to sort by
 */
export function sortBy<TElement>(
  values: TElement[],
  prop: SortProp<TElement>,
): SortedArray<TElement> {
  return new SortedArray(...values).thenBy(prop);
}
/**
 * Sorts elements in array by descending order
 * @param values Elements to sort
 * @param prop Property to sort by
 */
export function sortByDesc<TElement>(
  values: TElement[],
  prop: SortProp<TElement>,
): SortedArray<TElement> {
  return new SortedArray(...values).thenByDesc(prop);
}
