import { BaseClient } from '@app-clients/base.client';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { Injectable } from '@angular/core';
import { ContentDump } from '@app-models/content/content-dump';

/**
 * Client for retrieving content for an assessment
 */
@Injectable()
export class ContentClient extends BaseClient {
  constructor(httpClient: HttpClient, env: AppEnvironment) {
    super('content', httpClient, env);
  }

  /**
   * Gets all content for an assessment revision
   * @param assessmentRevisionId ID of the assessment revision
   */
  async getContent(
    assessmentRevisionId: string,
    isPaperPencil = false,
  ): Promise<ContentDump> {
    return await this.get<ContentDump>(
      [assessmentRevisionId],
      [{ isPaperPencil: isPaperPencil }],
    );
  }
}
