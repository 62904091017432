import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountClient } from '@app-clients/account/account.client';
import { AccountService } from '@app-state/account/account.service';
import { AccountQuery } from '@app-state/account/account.query';
import { AccountStore } from '@app-state/account/account.store';
import { AccountOwnerService } from '@app-state/account/account-owner.service';

/**
 * Holds common logic for user accounts
 */
@NgModule({
  imports: [CommonModule],
  providers: [
    AccountClient,
    AccountService,
    AccountQuery,
    AccountStore,
    AccountOwnerService,
  ],
})
export class AccountServiceModule {}
