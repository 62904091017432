import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app-services/security/auth.service';
import { ThemeService } from '@app-services/environment/theme.service';
import { Account } from '@app-models/account/account';
import { AccountOwnerService } from '@app-state/account/account-owner.service';

@Component({
  selector: 'cp-cus-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  private _accountName = '';

  protected visibleAccounts?: Account[];

  protected get accountName(): string {
    return this._accountName;
  }

  protected set accountName(value: string) {
    this._accountName = value;
    this.visibleAccounts = this.getFilteredAccounts(value);
  }

  constructor(
    protected readonly authService: AuthService,
    protected readonly theme: ThemeService,
    protected readonly accountOwnerService: AccountOwnerService,
  ) {}

  ngOnInit(): void {
    this.visibleAccounts = this.getUserAccountsWithoutCurrent();
  }

  private getFilteredAccounts(value?: string): Account[] {
    if (!value || !this.authService.currentAccount) {
      return this.getUserAccountsWithoutCurrent();
    }

    return this.authService.userAccounts.filter(
      account =>
        account.accountId !== this.authService.currentAccount.accountId &&
        account.name.toLowerCase().includes(value.toLowerCase()),
    );
  }

  private getUserAccountsWithoutCurrent(): Account[] {
    return this.authService.userAccounts.filter(
      a => a.accountId !== this.authService.currentAccount.accountId,
    );
  }

  protected setLastAccount(): void {
    if (this.visibleAccounts.length !== 1) return;
    this.authService.switchAccount(this.visibleAccounts[0]);
  }

  protected isAccountOwner(accountId: string): boolean {
    return this.accountOwnerService.userOwnsAccount(
      this.authService.currentUser.personId,
      accountId,
    );
  }
}
