import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@app-services/security/auth.service';
import { CompassRole } from '@app-models/roles';
import { SessionService } from '@app-state/session';

/**
 * Guards against unauthorized page access. Use in conjunction route data and
 * pass allowed roles into the data parameter of a route.
 * (e.g. data: {
 *   'allowedRoles': [UserRole.User]
 * }.
 */
@Injectable()
export class AccountRoleGuard {
  constructor(
    private readonly _authService: AuthService,
    private readonly _sessionService: SessionService,
    private readonly _router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // Init the session if it has not been. This can occur when a user refreshes on a protected page.
    if (!this._sessionService.isInitialized) {
      await this._sessionService.startSession();
    }
    const allowedRoles = route.data.allowedRoles as CompassRole[];

    if (
      !allowedRoles?.some(role => this._authService.currentUserRole === role)
    ) {
      return this._router.navigateByUrl('/');
    }

    return true;
  }
}
