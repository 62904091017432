/**
 * Type alias for sorting function that selects the property to sort on.
 */
export type SortProp<TElement> = (el: TElement) => string | number | boolean;

/**
 * Extended array class that has been sorted using the custom soring functions.
 */
export class SortedArray<TElement> extends Array<TElement> {
  private _sorts: {
    prop: SortProp<TElement>;
    order: 'asc' | 'desc';
  }[] = [];

  /**
   * Clears the applied sorting and starts sorting over again using ascending sorting
   * @param prop Property to sort by
   */
  sortBy(prop: SortProp<TElement>): this {
    this._sorts = [];
    this.thenBy(prop);

    return this;
  }
  /**
   * Clears the applied sorting and starts sorting over again using descending sorting
   * @param prop Property to sort by
   */
  sortByDesc(prop: SortProp<TElement>): this {
    this._sorts = [];
    this.thenByDesc(prop);

    return this;
  }

  /**
   * Applies next sorting in ascending order
   * @param prop Property to sort by
   */
  thenBy(prop: SortProp<TElement>): this {
    return this.thenByInternal(prop, 'asc');
  }

  /**
   * Applies next sorting in descending order
   * @param prop Property to sort by
   */
  thenByDesc(prop: SortProp<TElement>): this {
    return this.thenByInternal(prop, 'desc');
  }

  /**
   * Applies soring to the internal array of elements
   * @param prop Property to sort by
   * @param order Order of soring
   * @private
   */
  private thenByInternal(
    prop: SortProp<TElement>,
    order: 'asc' | 'desc',
  ): this {
    this._sorts.push({ prop, order });
    this.sort(this.compareFn.bind(this));

    return this;
  }

  /**
   * Compare function that decides the order of soring for given sort index
   * @param a First item
   * @param b Second item
   * @param sortFnIndex The current sort index
   * @private
   */
  private compareFn(a: TElement, b: TElement, sortFnIndex = 0): number {
    return this._sorts[sortFnIndex].order === 'asc'
      ? this.compareFnAsc(a, b, sortFnIndex)
      : this.compareFnDesc(a, b, sortFnIndex);
  }

  /**
   * Compare function for elements for given sort index using ascending comparison
   * @param a First item
   * @param b Second item
   * @param sortFnIndex The current sort index
   * @private
   */
  private compareFnAsc(a: TElement, b: TElement, sortFnIndex = 0): number {
    const sortFn = this._sorts[sortFnIndex];
    if (sortFn.prop(a) > sortFn.prop(b)) {
      return 1;
    } else if (sortFn.prop(a) < sortFn.prop(b)) {
      return -1;
    }

    return sortFnIndex + 1 === this._sorts.length
      ? 0
      : this.compareFn(a, b, sortFnIndex + 1);
  }

  /**
   * Compare function for elements for given sort index using descending comparison
   * @param a First item
   * @param b Second item
   * @param sortFnIndex The current sort index
   * @private
   */
  private compareFnDesc(a: TElement, b: TElement, sortFnIndex = 0): number {
    const sortFn = this._sorts[sortFnIndex];
    if (sortFn.prop(a) < sortFn.prop(b)) {
      return 1;
    } else if (sortFn.prop(a) > sortFn.prop(b)) {
      return -1;
    }

    return sortFnIndex + 1 === this._sorts.length
      ? 0
      : this.compareFn(a, b, sortFnIndex + 1);
  }
}
