import { Injectable } from '@angular/core';
import { BaseClient } from '@app-clients/base.client';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { Question } from '@app-models/content/question';
import { ParticipantResponse } from '@app-models/participant';

@Injectable()
export class AssessmentQuestionClient extends BaseClient {
  constructor(httpClient: HttpClient, appEnv: AppEnvironment) {
    super('questions', httpClient, appEnv);
  }

  getRevisionQuestions(assessmentRevisionId: string): Promise<Question[]> {
    return this.get<Question[]>(['assessmentRevision', assessmentRevisionId]);
  }

  getQuestionsForParticipantRevision(
    participantAssessmentRevisionId: string,
  ): Promise<ParticipantResponse[]> {
    return this.get<ParticipantResponse[]>([
      'participantRevision',
      participantAssessmentRevisionId,
    ]);
  }
}
