import { EntityStore, StoreConfig } from '@datorama/akita';
import { ParticipantResponseState } from '@app-state/participant-response/participant-response.state';
import { Stores } from '@app-state/shared/stores-configuration';

/**
 * Store for storing participant responses
 */
@StoreConfig(Stores.participantResponseState)
export class ParticipantResponseStore extends EntityStore<ParticipantResponseState> {
  constructor() {
    super();
  }
}
