import { User } from '@app-models/user/user';
import { Account } from '@app-models/account/account';
import { Injectable, OnDestroy } from '@angular/core';
import { CompassRole } from '@app-models/roles';
import { BehaviorSubject, Subject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { SubscriptionManager } from '@app-utils/subscription-manager';
import { SessionQuery, SessionState } from '@app-state/session';
import { AccountQuery, AccountService } from '@app-state/account';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AccountOwnerService } from '@app-state/account/account-owner.service';

/**
 * Service that provides information about currently logged in user
 * or lack of thereof.
 */
@Injectable()
export class AuthService implements OnDestroy {
  private readonly _subscriptions = new SubscriptionManager();

  private _currentUser: User;
  private _currentAccount: Account;
  private _userAccounts: Account[];

  readonly isAuthenticated$ = new BehaviorSubject<boolean>(false);
  readonly currentAccount$ = new Subject<Account>();

  constructor(
    private readonly _keycloak: KeycloakService,
    private readonly _sessionQuery: SessionQuery,
    private readonly _accountService: AccountService,
    private readonly _accountQuery: AccountQuery,
    private readonly _router: Router,
    private readonly _accountOwnerService: AccountOwnerService,
  ) {
    this._subscriptions.subscribe(
      this._sessionQuery.selectState$,
      this.sessionUpdated.bind(this),
    );
    this._subscriptions.add(
      this._accountQuery
        .selectAll()
        .subscribe(accounts => this.sortByOwnedAccounts(accounts)),
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribeAll();
  }

  /**
   * Indicates whether user is authenticated
   */
  get isAuthenticated(): boolean {
    return this.isAuthenticated$.getValue();
  }

  /**
   * Gets the current user logged in to the system.
   */
  get currentUser(): User {
    return this._currentUser;
  }

  get isAccountOwner(): boolean {
    return this._accountOwnerService.userOwnsCurrentAccount(
      this._currentUser.personId,
    );
  }

  /**
   * Gets the role for the current user and current account.
   */
  get currentUserRole(): CompassRole {
    return this._currentUser?.role;
  }
  /**
   * Gets the account currently selected for the logged in user.
   */
  get currentAccount(): Account {
    return this._currentAccount;
  }

  get usesExternalIdentityProvider(): boolean {
    return this.currentAccount.usesExternalIdentityProvider;
  }

  /**
   * Returns array of accounts user belongs to.
   * Accounts are sorted with owned accounts appearing first.
   */
  get userAccounts(): Account[] {
    return this._userAccounts;
  }

  /**
   * Gets whether current user has global role
   */
  get isSysAdminOrRoot(): boolean {
    return (
      this.currentUserRole === CompassRole.SystemAdministrator ||
      this.currentUserRole === CompassRole.Root
    );
  }

  get isMyParticipantsDefaultChecked(): boolean {
    return this._currentUser.isMyParticipantsCheckedDefault;
  }

  /**
   * Redirects back to login page
   */
  async redirectToLogin(): Promise<void> {
    await this._keycloak.login({
      redirectUri: window.location.origin,
    });
  }

  /**
   * Checks whether provided user is the current user
   * @param user User to check
   */
  isCurrentUser(user: Partial<User>): boolean {
    return user?.personId === this.currentUser?.personId;
  }

  /**
   * Checks whether provided person ID belongs to the current user
   * @param personId Person ID to check
   */
  isCurrentUserById(personId: string): boolean {
    return personId && this.isCurrentUser({ personId });
  }

  /**
   * Switches the account for the currently authenticated user
   * @param account The account to switch to
   */
  switchAccount(account: Account): void {
    this._accountService.selectAccount(account);
  }

  /**
   * Gets Keycloak Role (publisher, etc.)
   */
  hasHighMatchRole(role: string): boolean {
    return this._keycloak.isUserInRole(role);
  }

  /**
   * Closes user session and logs out of the application
   */
  async logout(): Promise<void> {
    await this._keycloak.logout();
  }

  /**
   * Sorts the user account list by owned accounts, then account name
   * @param accounts list of accounts to sort
   */
  private sortByOwnedAccounts(accounts: Account[]): void {
    if (!this._currentUser) {
      this._userAccounts = accounts;
      return;
    }

    const ownedAccounts = this._accountOwnerService.getOwnedAccounts(
      this.currentUser.personId,
    );

    const ownedAccountNames = ownedAccounts.map(acc => acc.accountName);

    this._userAccounts = accounts.sort((accountA, accountB) => {
      const isAOwned = ownedAccountNames.includes(accountA.name);
      const isBOwned = ownedAccountNames.includes(accountB.name);

      if (isAOwned === isBOwned) {
        return 0;
      }

      return isAOwned ? -1 : 1;
    });
  }

  private async sessionUpdated(sessionState: SessionState): Promise<void> {
    const isSameAccount =
      this.currentAccount?.accountId === sessionState.account?.accountId;

    this._currentUser = sessionState.user;
    this._currentAccount = sessionState.account;

    this.updateAuthenticationStatus();

    if (!isSameAccount) {
      if (this.canStayOnPage(this.currentUser.role)) {
        this.currentAccount$.next(this.currentAccount);
      } else {
        await this._router.navigateByUrl('/');
      }

      this.sortByOwnedAccounts(this._userAccounts);
    }
  }
  private updateAuthenticationStatus(): void {
    const isAuthenticated =
      this._currentUser !== null && this._currentAccount !== null;
    this.isAuthenticated$.next(isAuthenticated);
  }

  /**
   * After account change we have to check if there are any route restrictions
   * @private
   */
  private canStayOnPage(userRole: CompassRole): boolean {
    const allowedRoles = this.getAllowedRolesFromRoute(
      this._router.routerState.snapshot.root,
    );

    // If no roles are defined on the route or if they meet the role criteria then the user can stay
    return !allowedRoles || allowedRoles.some(role => role === userRole);
  }
  private getAllowedRolesFromRoute(
    route: ActivatedRouteSnapshot,
  ): CompassRole[] | undefined {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const allowedRoles = route.data['allowedRoles'];

    return route.children.length === 0
      ? allowedRoles
      : this.getAllowedRolesFromRoute(route.children[0]);
  }
}
