import { Injectable } from '@angular/core';
import { ReminderScheduleClient } from '@app-clients/reminder-schedule/reminder-schedule.client';
import { ReminderScheduleStore } from '@app-state/reminder-schedule/reminder-schedule.store';
import { ReminderScheduleQuery } from '@app-state/reminder-schedule/reminder-schedule.query';
import { Reminder } from '@app-models/reminders/reminders';
import { SenderType } from '@app-models/reminders/SenderType';

@Injectable()
export class ReminderScheduleService {
  constructor(
    private readonly _reminderScheduleClient: ReminderScheduleClient,
    private readonly _reminderScheduleStore: ReminderScheduleStore,
    private readonly _reminderScheduleQuery: ReminderScheduleQuery,
  ) {}

  /**
   * Loads all reminder schedules from remote server
   */
  async loadReminderSchedule(): Promise<void> {
    this._reminderScheduleStore.setLoading(true);
    const reminders = await this._reminderScheduleClient.getReminderSchedule();

    this._reminderScheduleStore.set(reminders);
    this._reminderScheduleStore.setLoading(false);
  }

  /**
   * Gets all loaded reminders
   * @private
   */
  private getReminders(validEmail: boolean, validSms: boolean): Reminder[] {
    let reminder: Reminder[] = [];

    let senderTypeId: number;

    if (validEmail && validSms) {
      senderTypeId = SenderType.Both;
    } else if (validEmail) {
      senderTypeId = SenderType.Email;
    } else if (validSms) {
      senderTypeId = SenderType.SMS;
    } else {
      senderTypeId = SenderType.None;
    }

    this._reminderScheduleQuery.selectAll().subscribe(reminders => {
      let filteredReminders: Reminder[];

      if (senderTypeId === SenderType.Both) {
        filteredReminders = reminders.filter(
          reminder =>
            reminder.senderTypeId === SenderType.Email ||
            reminder.senderTypeId === SenderType.SMS,
        );
      } else if (senderTypeId) {
        filteredReminders = reminders.filter(
          reminder => reminder.senderTypeId === senderTypeId,
        );
      } else {
        filteredReminders = reminders;
      }

      reminder = filteredReminders;
    });

    return reminder;
  }

  /**
   * Groups loaded reminders by time.
   * @private
   */
  private groupRemindersByTime(
    validEmail: boolean,
    validSms: boolean,
  ): { [day: number]: { reminders: Reminder[]; unit: string } } {
    const reminders = this.getReminders(validEmail, validSms);

    const groupedReminders: {
      [day: number]: { reminders: Reminder[]; unit: string };
    } = {};

    reminders.forEach(reminder => {
      const time = this.convertInterval(reminder.intervalSeconds);

      if (!groupedReminders[time.value]) {
        groupedReminders[time.value] = {
          reminders: [],
          unit: `${time.unit}`,
        };
      }

      groupedReminders[time.value].reminders.push(reminder);
    });

    return groupedReminders;
  }

  /**
   * Converts the interval given to either days, minutes, or leaves it as seconds.
   * @param seconds
   * @private
   */
  private convertInterval(seconds: number): { value: number; unit: string } {
    if (seconds >= 86400) {
      return { value: seconds / 86400, unit: 'days' };
    } else if (seconds >= 60) {
      return { value: seconds / 60, unit: 'minutes' };
    } else {
      return { value: seconds, unit: 'seconds' };
    }
  }

  /**
   * Generates the text used to show the reminder schedule.
   */
  public generateReminderText(
    validEmail: boolean,
    validSMS: boolean,
  ): string[] {
    const reminderText: string[] = [];
    const groupedReminders = this.groupRemindersByTime(validEmail, validSMS);

    for (const interval in groupedReminders) {
      const { reminders, unit } = groupedReminders[interval];

      const emailMessages = this.getMessagesForType(
        { reminders, unit },
        SenderType.Email,
      );
      const smsMessages = this.getMessagesForType(
        { reminders, unit },
        SenderType.SMS,
      );

      const messageParts: string[] = [];

      if (emailMessages.length > 0) {
        messageParts.push('Email');
      }

      if (smsMessages.length > 0) {
        messageParts.push('SMS/Text');
      }

      if (messageParts.length > 0) {
        const reminderLabel = messageParts.join(' and ');
        if (parseInt(interval) === 1 && unit === 'days') {
          reminderText.push(`${reminderLabel} reminder tomorrow.`);
        } else {
          reminderText.push(
            `${reminderLabel} reminder in ${interval} ${unit}.`,
          );
        }
      }
    }

    return reminderText;
  }

  /**
   * Gets the messages for the specific send type (Email or SMS)
   * @param reminders
   * @param senderType
   * @private
   */
  private getMessagesForType(
    reminders: { reminders: Reminder[]; unit: string },
    senderType: SenderType,
  ): string[] {
    return reminders.reminders
      .filter(reminder => reminder.senderTypeId === senderType)
      .map(reminder => reminder.description);
  }
}
