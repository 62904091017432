import { QueryEntity } from '@datorama/akita';
import { ParticipantResponseStore } from '@app-state/participant-response/participant-response.store';
import { ParticipantResponseState } from '@app-state/participant-response/participant-response.state';
import { Injectable } from '@angular/core';

/**
 * Class for querying participant response store
 */
@Injectable()
export class ParticipantResponseQuery extends QueryEntity<ParticipantResponseState> {
  constructor(participantResponseStore: ParticipantResponseStore) {
    super(participantResponseStore);
  }
}
