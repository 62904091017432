import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ReminderScheduleState } from '@app-state/reminder-schedule/reminder-schedule.state';
import { Stores } from '@app-state/shared/stores-configuration';

@Injectable()
@StoreConfig(Stores.reminderSchedules)
export class ReminderScheduleStore extends EntityStore<ReminderScheduleState> {
  constructor() {
    super();
  }
}
