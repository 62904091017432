import { Injectable } from '@angular/core';
import { ParticipantResponseStore } from '@app-state/participant-response/participant-response.store';
import { AssessmentQuestionClient } from '@app-clients/assessment/assessment-question.client';
import { ParticipantResponseQuery } from '@app-state/participant-response/participant-response.query';

/**
 * Class for manipulating participant response store
 */
@Injectable()
export class ParticipantResponseService {
  constructor(
    private readonly _participantResponseStore: ParticipantResponseStore,
    private readonly _participantResponseQuery: ParticipantResponseQuery,
    private readonly _questionClient: AssessmentQuestionClient,
  ) {}

  /**
   * Loads responses for participant revision
   * @param participantAssessmentRevisionId ID of the participant revision
   * @param forceReload Optional. If set to true then a server request is made regardless of load status. Default false.
   */
  async loadForParticipantRevision(
    participantAssessmentRevisionId: string,
    forceReload = false,
  ): Promise<void> {
    //By default do not load new data from server if loaded
    if (
      !forceReload &&
      this._participantResponseQuery.hasEntity(participantAssessmentRevisionId)
    )
      return;

    this._participantResponseStore.setLoading(true);

    const responses =
      await this._questionClient.getQuestionsForParticipantRevision(
        participantAssessmentRevisionId,
      );
    this._participantResponseStore.add({
      participantAssessmentRevisionId,
      responses,
    });

    this._participantResponseStore.setLoading(false);
  }
}
