import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

/**
 * Directive that will emit a value from an input with
 * the option to debounce the emitted value.
 */
@Directive({
  selector: '[cpCusObserveValue] input',
})
export class ObserveValueDirective {
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('cpCusObserveValue')
  readonly observeValue = new EventEmitter<unknown>();

  /**
   * Value debounce time in milliseconds
   */
  @Input()
  debounce = 0;

  private _lastValue: unknown = null;
  private _timeout: number;

  constructor(private readonly _hostElement: ElementRef) {}

  @HostListener('input', ['$event'])
  onEvent(): void {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }

    if (this.debounce > 0) {
      this._timeout = window.setTimeout(
        this.checkForNewValue.bind(this),
        this.debounce,
      );
    } else {
      this.checkForNewValue();
    }
  }

  private checkForNewValue(): void {
    const currentValue = this.getCurrentValue();
    if (currentValue !== this._lastValue) {
      this._lastValue = currentValue;
      this.observeValue.emit(currentValue);
    }
  }

  private getCurrentValue(): unknown {
    try {
      return this._hostElement.nativeElement?.value;
    } catch {
      return null;
    }
  }
}
