import { NgModule } from '@angular/core';
import { AccountServiceModule } from '@app-modules/domain/account/accounts-service.module';
import { AuthService } from '@app-services/security/auth.service';
import { UserServiceModule } from '@app-modules/domain/user/user-service.module';
import { SessionQuery, SessionService, SessionStore } from '@app-state/session';
import { AccountRoleGuard } from '@app-guards/account-role.guard';

/**
 * Holds logic related to user authentication
 */
@NgModule({
  imports: [AccountServiceModule, UserServiceModule],
  providers: [
    AuthService,
    SessionService,
    SessionStore,
    SessionQuery,
    AccountRoleGuard,
  ],
})
export class AuthenticationServiceModule {}
