<div
  class="flex justify-between w-full p-2 mb-2 rounded {{
    color
  }} dark:text-black dark:bg-gray-300">
  <div class="flex gap-3 flex-row items-center">
    <div>
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    </div>
    <div class="flex flex-wrap flex-col">
      <p *ngIf="title" class="font-bold m-0">{{ title }}</p>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="flex items-center gap-4">
    <ng-content select="[snackbarAction]"></ng-content>

    <button
      *ngIf="closeable"
      mat-icon-button
      (click)="snackbarClosed.emit()"
      matTooltip="Close this messsge">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
