import { Component, OnInit } from '@angular/core';
import { FreezeUi } from '@app-services/ui/freeze-ui.service';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '@app-services/security/auth.service';

@Component({
  selector: 'cp-cus-user-not-found',
  templateUrl: './user-not-found.component.html',
})
export class UserNotFoundComponent implements OnInit {
  constructor(
    private readonly _freezeUi: FreezeUi,
    private readonly _keycloak: KeycloakService,
    private readonly _authService: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this._keycloak.isLoggedIn()) {
      await this._keycloak.logout(location.href);
    }
    this._freezeUi.unfreezeUi();
  }

  async navigateToLogin(): Promise<void> {
    //Redirects back to login with no deep linking
    await this._authService.redirectToLogin();
  }
}
