import { Injectable } from '@angular/core';
import { BaseClient } from '@app-clients/base.client';
import { HttpClient } from '@angular/common/http';
import { AppEnvironment } from '@app-services/environment/app-environment.service';
import { Reminder } from '@app-models/reminders/reminders';

@Injectable()
export class ReminderScheduleClient extends BaseClient {
  constructor(httpClient: HttpClient, appEnvironment: AppEnvironment) {
    super('reminders', httpClient, appEnvironment);
  }

  getReminderSchedule(): Promise<Reminder[]> {
    return this.get<Reminder[]>();
  }
}
