import { ContentClient } from '@app-clients/assessment/content-client.service';
import { Injectable } from '@angular/core';
import { ContentDump } from '@app-models/content/content-dump';

/**
 * Class for manipulating assessment content store
 */
@Injectable()
export class ContentService {
  constructor(private readonly _client: ContentClient) {}

  /**
   * Loads necessary content for given revision
   * @param assessmentRevisionId ID of the assessment revision
   */
  async loadContentForRevision(
    assessmentRevisionId: string,
    isPaperPencil = false,
  ): Promise<ContentDump> {
    return await this._client.getContent(assessmentRevisionId, isPaperPencil);
  }

  /**
   * Loads necessary content for given revision
   * @param assessmentRevisionId
   * Includes scoring bucket information
   * @param isPaperPencil
   */
  async loadContentForBuckets(
    assessmentRevisionId: string,
    isPaperPencil = true,
  ): Promise<ContentDump> {
    return await this._client.getContent(assessmentRevisionId, isPaperPencil);
  }
}
