import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileHandlingService {
  async downloadFile(fileName: string, file: Blob): Promise<void> {
    const data = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName || 'Compass_CSV_Extract';
    link.click();
    window.URL.revokeObjectURL(data);
  }
}
