import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiModule } from '@app-modules/ui.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpClientModule } from '@angular/common/http';
import { environment, keycloakConfig } from '@environments/environment';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ApplicationEnvironment } from '@environments/application-environment';
import { MatNativeDateModule } from '@angular/material/core';
import { NotificationModule } from '@app-modules/ui/notification.module';
import { PrintModule } from '@app-modules/ui/print.module';
import { MathRenderer } from '@highmatch/utilities';
import { CompassHttpModule } from '@highmatch/compass/http';
import {
  CompassElementsModule,
  ElementRegistry,
} from '@highmatch/compass/elements';
import { MathComponent } from '@highmatch/compass/math';
import { IdentityComponent } from '@app-components/ui/custom-elements/identity/identity.component';
import { PendingAssessmentsComponent } from '@app-components/ui/custom-elements/pending-assessments/pending-assessments.component';
import { ReportComponent } from '@app-components/ui/custom-elements/report/report.component';
import { UserNotFoundComponent } from './@pages/user-not-found/user-not-found.component';
import { MatIconModule } from '@angular/material/icon';
import Keycloak from 'keycloak-js';
import { AuthenticationServiceModule } from '@app-modules/authentication/authentication-service.module';
import { AssessmentServiceModule } from '@app-modules/domain/assessment/assessment-service.module';
import { ButtonsModule } from '@app-modules/ui/buttons.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AppComponent, UserNotFoundComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UiModule,
    MatNativeDateModule,
    NotificationModule,
    PrintModule,
    KeycloakAngularModule,
    MatIconModule,
    AuthenticationServiceModule,
    AssessmentServiceModule,
    environment.applicationEnvironment === ApplicationEnvironment.Production
      ? []
      : AkitaNgDevtools.forRoot(),
    CompassHttpModule.forRoot(environment.baseApiUrl),
    CompassElementsModule.forRoot(),
    ButtonsModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [KeycloakService],
    },
    MathRenderer,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(elementRegistry: ElementRegistry) {
    elementRegistry.register('cp-math', MathComponent);
    elementRegistry.register('cp-identity', IdentityComponent);
    elementRegistry.register(
      'cp-pending-assessments',
      PendingAssessmentsComponent,
    );
    elementRegistry.register('cp-report', ReportComponent);
  }
}

function initApp(keycloak: KeycloakService): () => Promise<void> {
  return () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async resolve => {
      // If we cannot init KC (session expired, etc.) then resolve the promise immediately.
      // The app will get redirected to the login screen.
      if (!(await initKeycloak(keycloak))) {
        resolve();
        return;
      }

      const keycloakInstance = keycloak.getKeycloakInstance();

      setKeycloakEvents(keycloakInstance);
      resolve();
    });
  };
}

function initKeycloak(keycloak: KeycloakService): Promise<boolean> {
  return keycloak.init({
    config: keycloakConfig,
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
      enableLogging:
        environment.applicationEnvironment ===
        ApplicationEnvironment.Development,
    },
  });
}
function setKeycloakEvents(keycloak: Keycloak): void {
  keycloak.onAuthRefreshError = () => keycloak.logout();
}
