import { Injectable } from '@angular/core';
import { DataExtractClient } from '@app-clients/assessment/data-extract-client.service';
import { ExtractCsvDataQuery } from '@app-models/assessment/extract-data/extract-csv-data-query';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ParticipantExtractQuery } from '@app-models/participant/participant-extract-query';
import { FileHandlingService } from '@app-services/application/file-handling.service';
import { ResponseError } from '@app-responses/response-error';
import { EventLogExtractQuery } from '@app-models/account/event-log-extract-query';
import { ExtractServiceResponse } from '@app-models/assessment/extract-data/extract-service-response';

@Injectable()
export class DataExtractService {
  private _revisionExtractData: ExtractCsvDataQuery;
  private _participantExtractData: ParticipantExtractQuery;
  private _eventLogExtractData: EventLogExtractQuery;

  canDownload$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _dataExtractClient: DataExtractClient,
    private readonly _fileHandlingService: FileHandlingService,
  ) {}

  async extractRevisionCsvData(): Promise<boolean> {
    try {
      const response = await this._dataExtractClient.getExtractData(
        this._revisionExtractData,
      );

      await this.downloadFile(response);

      return response.status === 200;
    } catch (error: unknown) {
      return false;
    }
  }

  async extractParticipantActivity(): Promise<ExtractServiceResponse> {
    return this.extractData(
      () =>
        this._dataExtractClient.getParticipantExtract(
          this._participantExtractData,
        ),
      'Participant CSV extract complete',
    );
  }

  async extractUserData(): Promise<ExtractServiceResponse> {
    return this.extractData(
      () => this._dataExtractClient.getUserExtract(),
      'User CSV extract complete',
    );
  }

  async extractEventLog(): Promise<ExtractServiceResponse> {
    return this.extractData(
      () =>
        this._dataExtractClient.getEventLogExtract(this._eventLogExtractData),
      'Event log CSV extract complete',
    );
  }

  private async extractData(
    clientAction: () => Promise<HttpResponse<Blob>>,
    successMessage: string,
  ): Promise<ExtractServiceResponse> {
    try {
      const response = await clientAction();

      await this.downloadFile(response);

      return {
        statusCode: response.status,
        message: `${successMessage}. Your file is named ${this.getFilename(
          response.headers,
        )}`,
      };
    } catch (e) {
      return {
        statusCode: e.error.status,
        message: e.error.errors
          .map((errorObject: ResponseError) => errorObject.message)
          .join(' '),
      };
    }
  }

  clearForm(): void {
    this._revisionExtractData = null;
    this._participantExtractData = null;
  }

  updateCanDownload(canDownload: boolean): void {
    this.canDownload$.next(canDownload);
  }

  private async downloadFile(response: HttpResponse<Blob>): Promise<void> {
    const filename = this.getFilename(response.headers);
    await this._fileHandlingService.downloadFile(filename, response.body);
  }

  private getFilename(headers: HttpHeaders): string {
    return JSON.parse(headers.get('file-meta-data')).CsvFilename;
  }

  set eventLogExtractData(eventLogExtractData: EventLogExtractQuery) {
    this._eventLogExtractData = eventLogExtractData;
  }

  set revisionExtractData(revisionExtractData: ExtractCsvDataQuery) {
    this._revisionExtractData = revisionExtractData;
  }

  set participantExtractData(participantExtractData: ParticipantExtractQuery) {
    this._participantExtractData = participantExtractData;
  }

  get participantExtractData(): ParticipantExtractQuery {
    return this._participantExtractData;
  }
}
