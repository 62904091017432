import { QueryEntity } from '@datorama/akita';
import { ScoringBucketState } from '@app-state/scoring-bucket/scoring-bucket.state';
import { Injectable } from '@angular/core';
import { ScoringBucketStore } from '@app-state/scoring-bucket/scoring-bucket.store';
import { ScoringBucket } from '@app-models/assessment';
import { AssessmentRevisionQuery } from '@app-state/assessment-revision';

/**
 * Class for querying the scoring bucket store
 */
@Injectable()
export class ScoringBucketQuery extends QueryEntity<ScoringBucketState> {
  constructor(
    scoringBucketStore: ScoringBucketStore,
    private readonly _assessmentRevisionQuery: AssessmentRevisionQuery,
  ) {
    super(scoringBucketStore);
  }

  /**
   * Gets scoring buckets for assessment revision
   * @param assessmentRevisionId ID of the assessment revision
   */
  getScoringBucketsForAssessmentRevision(
    assessmentRevisionId: string,
  ): ScoringBucket[] {
    return this.getAll({
      filterBy: entity => entity.assessmentRevisionId === assessmentRevisionId,
    });
  }
}
