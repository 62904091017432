import { NgModule } from '@angular/core';
import { AssessmentClient } from '@app-clients/assessment/assessment-client.service';
import { AssessmentQuestionClient } from '@app-clients/assessment/assessment-question.client';
import { ContentClient } from '@app-clients/assessment/content-client.service';
import {
  ParticipantResponseQuery,
  ParticipantResponseService,
  ParticipantResponseStore,
} from '@app-state/participant-response';
import {
  AssessmentQuery,
  AssessmentService,
  AssessmentStore,
} from '@app-state/assessment';
import { ContentService } from '@app-state/content';
import {
  AssessmentRevisionQuery,
  AssessmentRevisionStore,
} from '@app-state/assessment-revision';
import {
  ScoringBucketQuery,
  ScoringBucketStore,
} from '@app-state/scoring-bucket';
import {
  AssessmentVariantQuery,
  AssessmentVariantStore,
} from '@app-state/assessment-variant';
import { DataExtractService } from '@app-state/assessment-data-extract/data-extract.service';
import { DataExtractClient } from '@app-clients/assessment/data-extract-client.service';

@NgModule({
  providers: [
    DataExtractService,
    DataExtractClient,
    AssessmentClient,
    AssessmentStore,
    AssessmentQuery,
    AssessmentService,
    AssessmentRevisionStore,
    AssessmentRevisionQuery,
    ScoringBucketStore,
    ScoringBucketQuery,
    AssessmentQuestionClient,
    ParticipantResponseStore,
    ParticipantResponseQuery,
    ParticipantResponseService,
    ContentService,
    ContentClient,
    AssessmentVariantStore,
    AssessmentVariantQuery,
  ],
})
export class AssessmentServiceModule {}
